import menuData from '../data/menu.json'; // Adjust the path as necessary

const Menu = () => {
    return (
      <nav className="menu">
        {menuData.menu.map((menuItem, index) => (
          <div key={index} className="menu-item">
            {menuItem.title}
            <div className="submenu-box">
              <div className="submenu">
                {menuItem.items.map((subitem, subindex) => (
                  <div key={subindex} className="submenu-item">
                    {subitem}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </nav>
    );
  };
  
  export default Menu;