// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root {
  --background-light: #f6f0e9;
  --background-pink: #ff69b4;

  --primary-yellow: #ebf445;
  --primary-pink: #d62586;

  --primary-blue: #2cd3d3;
  --primary-purple: #71029c;

  --text-dark: #333333;
  --font-family: 'Sour Gummy', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":";AACA;EACE,2BAA2B;EAC3B,0BAA0B;;EAE1B,yBAAyB;EACzB,uBAAuB;;EAEvB,uBAAuB;EACvB,yBAAyB;;EAEzB,oBAAoB;EACpB,uCAAuC;AACzC","sourcesContent":["\n:root {\n  --background-light: #f6f0e9;\n  --background-pink: #ff69b4;\n\n  --primary-yellow: #ebf445;\n  --primary-pink: #d62586;\n\n  --primary-blue: #2cd3d3;\n  --primary-purple: #71029c;\n\n  --text-dark: #333333;\n  --font-family: 'Sour Gummy', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
