import React from 'react';
import '../styles/Header.css';
import Menu from './menu';

const Header = () => {
  return (
    <header>
      <div className="main-header">
        <div className="company-name">The Nail And Co.</div>
      </div>
      <Menu/>
    </header>
  );
};



export default Header;