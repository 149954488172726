import React from 'react';
import '../styles/Home.css'; // Ensure styles match the new theme look
import ImageBanner from './imageBanner';

const Home = () => {
  // Example products for demonstration
  const featuredProducts = [
    {
      id: 1,
      name: 'Elegant Extension Set',
      image: '/images/acrylic.jpg',
      price: '₹935.00'
    },
    {
      id: 2,
      name: 'Classic French Tips',
      image: '/images/french_tips.jpg',
      price: '₹1030.00'
    },
    {
      id: 3,
      name: 'Glitter Glam Set',
      image: '/images/glitter.jpg',
      price: '₹840.00'
    }
  ];

  return (
    <div className="home">
      <ImageBanner/>
      {/* <div className="hero-banner">
        <h1>Discover Our Stunning Nail Art</h1>
        <p>Explore the latest trends and styles in nail extensions</p>
        <button className="hero-button">Shop Now</button>
      </div> */}

      <section className="featured-products">
        <h2>Featured Products</h2>
        <div className="product-grid">
          {featuredProducts.map(product => (
            <div className="product-card" key={product.id}>
              <img src={product.image} alt={product.name} className="product-image" />
              <h3>{product.name}</h3>
              <p>{product.price}</p>
              <button className="add-to-cart">Add To Cart</button>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
